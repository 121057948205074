// Vendors
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { LoginContextConsumerHook } from 'containers/login/contexts/login.context';
// Event handlers
import LoginGapsApiHandlers from '../handlers/login-gaps-api.handlers';
import LoginGapsTrackingHandlers from '../handlers/login-gaps.tracking.handlers';
import LoginGapsUiHandlers from '../handlers/login-gaps-ui.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { useLoginGapInterface } from './types/login-gaps.hook.type';
import { useCaptchaContextHook } from 'contexts/captcha/captcha.context';

const useLoginGaps = (): useLoginGapInterface => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordPositions, setPasswordPositions] = useState<string[]>([]);

  const { formatMessage } = useCbIntl();

  const { email, rememberMe, setLoginView, error, setError } = LoginContextConsumerHook();
  const { captcha, captchaRef, setCaptcha } = useCaptchaContextHook();

  const {
      handleContinuePasswordGapsButtonClickTracking,
      handleEnterLoginPasswordPositionsViewTracking,
      handleForgetPasswordLinkClickTracking,
      handlePasswordPositionInputErrorTracking,
      handlePasswordPositionInputClickTracking,
    } = AppTrackingHook(LoginGapsTrackingHandlers),
    {
      handleSetupPasswordPositionsViewHandler,
      handleContinuePasswordGapsButtonClick,
      handleSetupKeyBoardClickForLogin,
    } = LoginGapsApiHandlers({
      captcha,
      captchaRef,
      email,
      handleContinuePasswordGapsButtonClickTracking,
      handleEnterLoginPasswordPositionsViewTracking,
      handlePasswordPositionInputErrorTracking,
      password,
      passwordPositions,
      rememberMe,
      setCaptcha,
      setFetching,
      setError,
      setLoginView,
      setPassword,
      setPasswordPositions,
    });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSetupPasswordPositionsViewHandler();
  }, []);

  useEffect(() => {
    handleSetupKeyBoardClickForLogin();
  }, [password]);

  return {
    ...LoginGapsUiHandlers({
      handleForgetPasswordLinkClickTracking,
      setPassword,
      setError,
      navigate: useNavigate(),
    }),
    captcha,
    fetching,
    formatMessage,
    error,
    handleContinuePasswordGapsButtonClick,
    handlePasswordPositionInputClickTracking,
    password,
  };
};

export { useLoginGaps };
