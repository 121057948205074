// Constants
import { PASSWORD_MAX_LENGTH } from './constants/login-email.utils.constants';
// Enumeration
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Types
import { LoginGapsUtilsType } from './types/login-gaps.utils.type';
// Utilities
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';
import { isValidCaptcha } from 'components/captcha/utils/captcha.utils';

const isValidPasswordPositions = (passwordPositions: string): boolean =>
  passwordPositions?.length === PASSWORD_MAX_LENGTH;

const isContinueButtonDisabled = ({
  captcha,
  fetching,
  password,
  error,
}: LoginGapsUtilsType): boolean =>
  Boolean(
    fetching ||
      error ||
      !isValidPasswordPositions(password) ||
      !isValidCaptcha({ captcha, featureFlag: FeatureFlagsEnumeration.CAPCTHA_LOGIN })
  );

export { isContinueButtonDisabled, isValidPasswordPositions };
